import { Link, useNavigate, useParams } from "react-router-dom"
import { useUser } from "../hooks/useUser"
import { useEffect, useState } from "react"
import axios from "axios"
import Loading from "./components/Loading"
import DownloadLink from "./components/DownloadLink"
import "./stylesheets/Applicants.css"
import AlertModal from "./components/AlertModal"
import { FaRegCheckCircle } from "react-icons/fa"

function Application({ data, job, curUser, navigate, setAlertMessage }) {

    async function handleAccept() {
        axios.post('/jobs/applicants/accept', {
            user: curUser.id,
            app: data._id,
            job: job._id
        })
            .then(result => {
                alert("Success")
                navigate(-1)
            })
            .catch(error => {
                if (error.response?.data.name === 'NOT_ENOUGH_CREDITS') {
                    setAlertMessage(error.response.data.message)
                    return;
                }
            })
    }

    return <div id="ApplicantPreview" >
        {data?.users?.map(u => {
            console.log(u)
            return <Link to={`/Profile/?userID=${u._id}`} target="_blank" style={{ textDecoration: 'none' }}>
                <h4 id="ApplicantsMap" style={{ marginBlock: 0 }}>@{u.username}</h4>
            </Link>
        })}
        {job?.isBid && <h4>Bid: {data.bid} EGP</h4>}
        {data.files?.length > 0 &&
            <div>
                {data.files.map(e => <DownloadLink
                    downloadName={e.name}
                    url={'/applications/files/download'}
                    requestBody={{ app: data._id, file: e._id, user: curUser.id }}
                >
                    <h4 style={{ fontSize: 12, fontStyle: 'italic', color: '#34aed4' }}>Download {e.name}</h4>
                </DownloadLink>)}
            </div>
        }
        <a onClick={handleAccept} id="AcceptApplicant">
            <FaRegCheckCircle id="ColorCircle" size={24} />
        </a>
    </div>
}

export default function Applicants() {

    const [applicants, setApplicants] = useState()
    const [job, setJob] = useState()

    const [alertMessage, setAlertMessage] = useState()

    // Other hooks
    const curUser = useUser()
    const navigate = useNavigate()
    const { id: jobId } = useParams()

    useEffect(() => {
        if (!jobId || !curUser.id) return;

        axios.get(`/jobs/applicants/${jobId}`, { params: { requester: curUser.id, getFiles: true } })
            .then(result => {
                setApplicants(result.data)
            })

        axios.get(`/jobs/view/${jobId}`)
            .then(result => {
                setJob(result.data.job)
            })
    }, [jobId, curUser.id])

    return (
        <div id="ApplicantsPage">
            <div id="ApplicantsWrapper">
                {!job ? <Loading /> :
                    <div id="LeftApplicants">
                        <div id="ApplicantsTopArea" style={{ display: 'flex', flexDirection: 'row', whiteSpace: 'pre-wrap', }}>
                            <h2 id="ApplicantHeader">Applicants for{''}</h2>
                            <h2 id="ApplicantsTitle" style={{ color: '#34AED4' }}>{job.title}</h2>
                        </div>
                        <h3 style={{ fontSize: '1.25svh', marginLeft: '1%', marginBottom: '1%', padding: 0, fontStyle: 'italic', color: '#ababab' }}>Description</h3>
                        <h3 id="ApplicantsDescription" >{job.description}</h3>
                        <div style={{ display: 'flex', justifyContent: 'center' }}>
                            <div id="ApplicantSquare">
                                <h3 id="ApplicantsDescription" style={{ color: '#00000080', fontWeight: 600 }}>Payment: {job.isBid ? "Up for bid" : `${job.price} EGP`}</h3>
                            </div>
                        </div>
                    </div>
                }
                {!applicants ? <Loading /> :
                    <div id="ApplicantsLayout">
                        {applicants.length === 0 && <h4 style={{ textAlign: 'center', marginTop: '1%', marginBottom: 0, fontSize: '1.5svh', color: '#00000050' }}>No Applicants</h4>}
                        {applicants.map(e =>
                            <div>
                                <h3 id="ApplicantDescription" style={{ textAlign: 'center', marginTop: '1%', marginBottom: 0, fontSize: '1.5svh' }}>Applicants</h3>
                                <Application key={e._id} data={e} job={job} curUser={curUser} navigate={navigate} setAlertMessage={setAlertMessage} />
                            </div>
                        )}
                    </div>
                }
            </div>
            <AlertModal
                show={!!alertMessage}
                text={alertMessage}
                buttonsVisible={true}
                b1={{ text: "Cancel", onClick: () => setAlertMessage() }}
                b2={{ text: "Add Credits", onClick: () => { navigate('/AddCredits'); setAlertMessage() } }}
            />

        </div>
    )
}