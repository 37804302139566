import React, { useEffect, useMemo, useRef, useState } from 'react'
import ProfileJobs from './ProfileJobs'
import { Link, useLocation, useNavigate } from 'react-router-dom';
import axios from 'axios';
import Loading from '../Loading';
import ProfileImage from '../ProfileImage';
import { maxUserFileSize, validImageTypes, validResumeTypes } from '../../../config.js';
import "../../stylesheets/Profile.css"
import ProfileSection from './ProfileSection.js';
import { FaChevronDown, FaChevronLeft, FaChevronRight, FaChevronUp } from 'react-icons/fa';

const availableAppStatusesForOwnProfile = {
    pending: {
        name: "Pending Jobs",
        url: '/jobs/listByStatus',
        params: { employer: true, status: 'available' }
    },
    progress: {
        name: 'Active Jobs',
        url: `/jobs/listByStatus`,
        params: { employer: true, status: 'progress' }
    },
    review: {
        name: 'In Review',
        url: '/jobs/listByStatus',
        params: { employer: true, status: 'review' }
    },
    complete: {
        name: "Complete",
        url: '/jobs/listByStatus',
        params: { employer: true, status: 'complete' }
    },
}

const availableAppStatusesForVisitor = {
    pending: {
        name: "Pending",
        url: '/jobs/availableCreatedBy',
    },
    complete: {
        name: "Complete",
        url: '/jobs/history',
    }
}

const Bio = ({ BioText, user, isCurUser, fieldName, fieldLabel, isBlack = false }) => {
    const [value, setValue] = useState(BioText)
    const [editing, setEditing] = useState(false)
    const [updating, setUpdating] = useState(false)

    function handleValueChange(e) {
        setValue(e.target.value)
    }

    function handleSaveValue(e) {
        setEditing(false)
        setUpdating(true)
        axios.put('/users/update', {
            user: user._id,
            [fieldName]: value
        })
            .catch(error => console.error(error))
            .finally(() => setUpdating(false))
    }
    return (
        <div className='Experience' style={{ backgroundColor: 'transparent', width: '90%', marginLeft: '2.5%', marginTop: '1%', display: 'flex' }}>
            {value.length > 0 && <div className='EnterBio' id='CoverBio'>
                <h3 style={{ color: isBlack ? 'black' : 'grey', fontStyle: 'italic', fontSize: '1em', lineHeight: '1.33em', textAlign: 'justify' }}>
                    {value}</h3>
            </div>}
            {updating ? <Loading /> : !editing ?
                isCurUser && <button id="isEditing" style={{ marginLeft: '7.5%' }} onClick={() => setEditing(true)}>
                    Edit {fieldLabel}</button>
                : <>
                    <textarea
                        type="text"
                        placeholder={`Enter your ${fieldLabel}`}
                        value={value}
                        onChange={handleValueChange}
                        style={{ width: '60%', marginRight: '2.5%', borderRadius: 10, borderWidth: .5, padding: 10, fontStyle: 'italic', maxHeight: '100svh', textAlign: 'justify' }}

                    />
                    <button style={{ width: "25%" }} onClick={handleSaveValue}>Save {fieldLabel}</button>
                </>
            }
        </div>
    )
}

const FieldsInterest = ({ BioText, user, isCurUser, fieldName, fieldLabel }) => {
    const [value, setValue] = useState(BioText);
    const [editing, setEditing] = useState(false);
    const [updating, setUpdating] = useState(false);

    function handleValueChange(e) {
        // Replace actual line breaks with '\n'
        setValue(e.target.value.replace(/\n/g, '\\n'));
    }

    function handleSaveValue() {
        setEditing(false);
        setUpdating(true);
        axios.put('/users/update', {
            user: user._id,
            [fieldName]: value
        })
            .catch(error => console.error(error))
            .finally(() => setUpdating(false));
    }

    // Convert '\n' back into actual line breaks for display
    function renderTextWithLineBreaks(text) {
        return text.split('\\n').map((line, index) => (
            <React.Fragment key={index}>
                {line}
                <br />
            </React.Fragment>
        ));
    }

    return (
        <div className='Experience' style={{ backgroundColor: 'transparent', width: '90%', marginLeft: '2.5%', marginTop: '1%', display: 'flex' }}>
            {value.length > 0 && (
                <div className='EnterBio' id='CoverBio'>
                    <h3 style={{ color: 'black', fontStyle: 'italic', fontSize: '1em', lineHeight: '1.33em' }}>
                        {renderTextWithLineBreaks(value)}
                    </h3>
                </div>
            )}
            {updating ? <Loading /> : !editing ?
                isCurUser && <button id="isEditing" style={{ marginLeft: '7.5%' }} onClick={() => setEditing(true)}>
                    Edit</button>
                : <>
                    <textarea
                        type="text"
                        placeholder={`Enter your ${fieldLabel}`}
                        value={value.replace(/\\n/g, '\n')}
                        onChange={handleValueChange}
                        style={{ width: '60%', marginRight: '2.5%', borderRadius: 10, borderWidth: .5, padding: 10, fontStyle: 'italic', maxHeight: '100svh' }}
                    />
                    <button style={{ width: "25%" }} onClick={handleSaveValue}>Save {fieldLabel}</button>
                </>
            }
        </div>
    );
};

const ActiveJob = ({ job, isCurUser, selectedAppStatus }) => {

    let buttonLink;
    let buttonText;
    if (isCurUser) {
        if (selectedAppStatus === 'pending') {
            buttonLink = `/Applicants/${job._id}`
            buttonText = 'View Applicants'
        } else {
            buttonLink = `/ActiveForsa/${job._id}`
            buttonText = 'View Job'
        }
    }
    else {
        buttonLink = `/ApplyForas/${job._id}`
        buttonText = 'Apply'
    }
    return <>
        <Link to={buttonLink} id='DisplayJobEmployer' style={{ textDecoration: 'none', cursor: 'pointer' }}>
            <h3 id="SavedTitle">{job.title}</h3>
            <div>
                <h4 id="SavedDesc" style={{ textWrap: 'wrap' }}>
                    {job.description.slice(0, 200)}{job.description.length > 200 ? '...' : ''}
                </h4>
            </div>
            {(isCurUser || selectedAppStatus === 'pending') && (
                <Link to={buttonLink}>
                    <button>
                        {buttonText}
                    </button>
                </Link>
            )}
        </Link>
    </>

};
export default function EmployerProfile({ employer, curUser }) {
    const isCurUser = useMemo(() => employer._id === curUser.id, [employer, curUser])
    const [history, setHistory] = useState(undefined)
    // const [activeJobs, setActiveJobs] = useState(undefined)
    const [selectedAppStatus, setSelectedAppStatus] = useState('personal')
    const [dataList, setDataList] = useState()
    const imageInputRef = useRef()
    const navigate = useNavigate()
    const location = useLocation()
    const [uploading, setUploading] = useState(false)

    const [imageProgress, setImageProgress] = useState()
    const [imageUploadedTime, setImageUploadedTime] = useState()

    const [showButtons, setShowButtons] = useState(true);

    const availableAppStatuses = useMemo(() => isCurUser ? availableAppStatusesForOwnProfile : availableAppStatusesForVisitor, [isCurUser])
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);

    function handleImageChoice(event) {
        if (!isCurUser) {
            return;
        }
        event.preventDefault()
        imageInputRef.current?.click()
    }
    const handleToggleVisibility = () => {
        setShowButtons(!showButtons);
    };

    /**
     * 
     * @param {"image" | "cv"} docType 
     * @returns 
     */
    const handleUploadFile = (docType) => function (event) {
        event.preventDefault();

        // Validating size
        const file = event.target.files[0]
        if (!file) {
            return;
        }

        if (file.size > maxUserFileSize) {
            console.error(`File too large. Max size is ${maxUserFileSize / (1024 * 1024)}MB`);
            event.target.value = null
            return;
        }

        const validTypes = docType === 'cv' ? validResumeTypes : validImageTypes

        // Validating file type
        if (!validTypes.split(',').includes(file.type)) {
            console.error("Invalid file type. Allowed types are: " + validTypes);
            event.target.value = null
            return;
        }

        // Creating form for axios

        if (!curUser.loggedIn) {
            navigate('/Login', { state: { from: location.pathname + location.search } })
            return;
        }

        const formData = new FormData();
        formData.append('user', curUser.id);
        formData.append('type', docType);
        formData.append('file', file)

        setImageProgress(0)
        setUploading(true)
        axios.post('/users/files/upload', formData, {
            onUploadProgress: e => {
                if (docType !== 'image') {
                    return;
                }
                setImageProgress(e.progress)
            }
        })
            .then(() => {
                if (docType !== 'image') {
                    return;
                }
                setImageUploadedTime(Date.now())
            })
            .catch(error => console.error(error))
            .finally(() => setUploading(false))

    }

    function genProfileHistory() {
        if (!history) {
            return <Loading />
        }

        return history.map(e => <ProfileJobs key={e._id} jobTitle={e.title} jobDesc={e.description} onClick={() => { navigate(`/ActiveForsa/${e._id}`) }} />)
    }

    function genActiveJobs() {
        if (!dataList) {
            return <Loading />
        }

        return dataList.map(job => {
            return <ActiveJob key={job._id} job={job} isCurUser={isCurUser} selectedAppStatus={selectedAppStatus} />
        })
    }

    function handleSelectedAppStatusChange(key) {
        setSelectedAppStatus(key)

        if (key === 'personal') return;

        const appStatusObj = availableAppStatuses[key]
        axios.get(appStatusObj.url + `/${employer._id}`, { params: { ...appStatusObj.params, requester: curUser.id } })
            .then(result => {

                // Workaround to keep schema similar for all keys
                if (key === 'progress') {
                    result.data.map(e => e.job = { ...e })
                }
                // console.log(result.data)
                setDataList(result.data)
            })
    }

    useEffect(() => {
        handleSelectedAppStatusChange(selectedAppStatus)
    }, [])

    useEffect(() => {
        axios.get(`/jobs/history/${employer._id}`, {
            params: {
                limit: 3,
                owner: true,
                requester: curUser.id
            }
        })
            .then(result => {
                setHistory(result.data)
            })
            .catch(error => {
                setHistory([])
                console.log(error);
            })

        // axios.get(`/jobs/createdBy/${employer._id}`)
        //   .then(result => setActiveJobs(result.data))
        //   .catch(error => {
        //     console.error(error);
        //   })
    }, [employer])

    return (
        <div className='ProfilePage'>
            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                <div className='ProfileTag' style={{ width: '100%' }}>
                    <ProfileImage ishover={true} progress={imageProgress} uploadedTime={imageUploadedTime} className='ProfileImg' id={employer._id} imageURL={employer.imageURL} style={{ cursor: isCurUser ? 'pointer' : 'inherit' }} onClick={handleImageChoice} />
                    <div className='ProfileDesc'>
                        <h2 style={{ color: 'black', margin: 0, }}>{employer.fullName}</h2>
                        <h3 style={{ marginTop: 0, margin: 0, }}>@{employer.username}</h3>
                        <h3 style={{ marginTop: 0, margin: 0, }}>Foras Employer</h3>
                    </div>
                </div>
                <div className='leftSide' style={{ display: 'flex', flexDirection: 'row', gap: '2.5%', marginRight: '10%' }}>
                    {isCurUser && <>
                        {employer.wallet && <div id="WalletCredits">
                            <h3 style={{ fontSize: '1.25svh', color: '#00000080' }}>Wallet: {employer.wallet.credits.toFixed(2)} credits</h3>
                        </div>}
                        <div className='applybutton' id='AddCreditsButton' style={{ width: '100%', padding: 0, height: '50%' }}>
                            <Link to="/AddCredits"><button style={{ width: 175, marginBottom: 10 }}>Add Credits</button></Link>
                        </div>

                        <input disabled={uploading} type='file' ref={imageInputRef} style={{ display: 'none' }} accept={validImageTypes} onInput={handleUploadFile('image')} />
                        {uploading && <Loading />}
                        <div className='applybutton' id='AddCreditsButton' style={{ width: '100%', padding: 0, height: '50%' }}>
                            <Link to="/AddForas"><button style={{ width: 175 }}>Add Foras</button></Link>
                        </div>
                    </>}
                </div>
            </div>
            <div className='AllProfile'>
                <div className='rightside'>
                    <div id='EmployerProfileSwitcher'>
                        <button id={showButtons ? 'HideButton' : 'HideButton2'} onClick={handleToggleVisibility} style={{ backgroundColor: showButtons ? 'white' : '#34AED4' }}>
                            {showButtons ? <FaChevronRight color={showButtons ? '#34AED4' : 'white'} /> : <FaChevronLeft color={showButtons ? '#34AED4' : 'white'} />}
                            <span style={{ marginLeft: '5px', color: showButtons ? 'white' : '#34AED4' }}>{showButtons ? '' : ''}</span> {/* Text changes based on visibility */}
                        </button>
                        {showButtons && ( // Conditionally render buttons
                            <>
                                <button
                                    id={selectedAppStatus === 'personal' ? 'SwitcherButtonsActive' : 'SwitcherButtons'}
                                    onClick={() => handleSelectedAppStatusChange('personal')}
                                    style={{
                                        backgroundColor: selectedAppStatus === 'personal' ? '#34AED4' : 'white',
                                        color: selectedAppStatus === 'personal' ? 'white' : 'black',
                                        width: windowWidth > 1024 ? '15%' : '25%',
                                    }}
                                >
                                    About
                                </button>
                                {Object.entries(availableAppStatuses).map(([key, value]) => (
                                    <button
                                        id={key === selectedAppStatus ? 'SwitcherButtonsActive' : 'SwitcherButtons'}
                                        onClick={() => handleSelectedAppStatusChange(key)}
                                        style={{
                                            backgroundColor: key === selectedAppStatus ? '#34AED4' : 'white',
                                            color: key === selectedAppStatus ? 'white' : 'black',
                                            width: '15%',
                                        }}
                                    >
                                        {value.name}
                                    </button>
                                ))}
                            </>
                        )}
                    </div>

                    {selectedAppStatus === 'personal' ? (
                        <div className='AllProfile'>
                            <div className='rightside' style={{ borderStyle: 'none' }}>
                                <div id='GetToKnowWrapper'>
                                    <div id='GetToKnowCompany'>
                                        <h3>
                                            Get to know <span style={{ fontWeight: 600 }}>{employer.fullName}</span>
                                        </h3>
                                    </div>
                                </div>
                                <div id='headerProfileTag'>
                                    <h2 id='headerProfileSection' style={{ fontStyle: 'italic', fontSize: '.88em', fontWeight: 400, color: 'white' }}>
                                        About Us
                                    </h2>
                                </div>
                                <Bio
                                    BioText={employer.bio}
                                    user={employer}
                                    isCurUser={isCurUser}
                                    fieldLabel={''}
                                    fieldName={'bio'}
                                    isBlack={true}
                                />
                                <div id='headerProfileTag' style={{ marginTop: '2.5%' }}>
                                    <h2 id='headerProfileSection' style={{ fontStyle: 'italic', fontSize: '.88em', fontWeight: 400, color: 'white' }}>
                                        Industry/Sector
                                    </h2>
                                </div>
                                <FieldsInterest
                                    BioText={employer.fieldsOfInterest || ''}
                                    user={employer}
                                    isCurUser={isCurUser}
                                    fieldLabel={'Industry/Sector'}
                                    fieldName={'fieldsOfInterest'}
                                />
                                  <div id='headerProfileTag' style={{ marginTop: '2.5%' }}>
                                    <h2 id='headerProfileSection' style={{ fontStyle: 'italic', fontSize: '.88em', fontWeight: 400, color: 'white' }}>
                                        Posts
                                    </h2>
                                </div>
                            </div>
                        </div>
                    ) : (
                        <>
                            <div id="HomeJobsEmployer">
                                <div className="SavedJobsText">
                                    <h2>Jobs</h2>
                                </div>
                                <div id='centerdiv'>{genActiveJobs()}</div>
                            </div>
                        </>
                    )}
                </div>
            </div>
            <h2 id="headerProfileSection" style={{ fontStyle: 'italic', fontSize: '1.33em', marginBottom: '1%', marginLeft: '5%', marginTop: '2.5%', fontWeight: 600 }}>Forsa History</h2>
            {genProfileHistory()}

        </div>
    )
}