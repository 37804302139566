import React, { useEffect, useMemo, useState } from 'react'
import './stylesheets/Login.css'
import PasswordStrengthIndicator from './pages/components/PasswordStrengthIndicator'
import axios from 'axios'
import { Link, useLocation, useNavigate, useSearchParams } from 'react-router-dom'
import Background from './pages/stylesheets/imgs/backgroundLogin.png'
import Loading from './pages/components/Loading'
import { checkPassStrength } from './utils/AuthUtils'
import { passwordStrengthThreshold } from './config'
import { getByDisplayValue } from '@testing-library/react'
import ImageBatch from './pages/components/ImageBatch'
import { replace } from 'lodash'
import AlertModal from './pages/components/AlertModal'


export default function Login_SignUp() {
    const navigate = useNavigate()
    const [searchParams, setSearchParams] = useSearchParams({ page: 0 })
    const page = parseInt(searchParams.get('page')) || 0
    const [formState, setFormState] = useState({ role: 'employee' })

    const [categories, setCategories] = useState([])

    const [activeButton, setActiveButton] = useState('Freelancer');
    const isEmployer = useMemo(() => formState.role === 'employer', [formState.role])
    const shouldSignUp = useMemo(() => (
        (!isEmployer && page === 1) || // Freelancer signs up on page 1
        (isEmployer && page === 2) // Employer signs up on page 2
    ), [page])

    const [errors, setErrors] = useState([]);
    const [loading, setLoading] = useState(false)

    const [strength, setStrength] = useState(0)

    function signup(event) {
        event.preventDefault()

        formState.lastName = isEmployer ? 'Employer' : formState.lastName; // Automatically set last name to "Employer"
        formState.dob = isEmployer ? null : formState.dob; // Automatically set last name to "Employer"
        const newErrors = [];

        if (formState.username.length === 0) {
            newErrors.push("Username is required");
        }

        if (formState.email.length === 0) {
            newErrors.push("Email is required");
        }

        if (formState.password.length === 0) {
            newErrors.push("Password is required");
        }

        if (formState.password2.length === 0) {
            newErrors.push("Password confirmation is required");
        }

        if (strength < passwordStrengthThreshold) {
            newErrors.push("Password must be at least 8 characters long and contain a combination of upper and lower case letters, numbers, and special characters.");
        }

        if (formState.password !== formState.password2) {
            newErrors.push("Passwords do not match");
        }

        if (newErrors.length > 0) {
            setErrors(newErrors);
            return false;
        }

        setErrors([]);
        // delete formState.password2

        setLoading(true)
        axios.post(`/users/signUp`, formState)
            .then(result => {
                setLoading(false)
                navigate('/SignUpComplete')
            })
            .catch(error => {
                setLoading(false)
                console.error(error)
            })
    }

    function updateFormField(field) {
        return function (event) {
            setFormState(prev => ({ ...prev, [field]: event.target.value }));
        }
    }

    const handleSelectUserRole = (buttonType) => {
        setActiveButton(buttonType);
    };

    const handleNext = (event) => {
        event.preventDefault()
        if (page === 0) {
            if (activeButton) {
                setSearchParams({ page: 1 })
            } else {
                alert('Please select an option');
            }

            return;
        }

        if (shouldSignUp) {
            signup(event)
        } else {
            setSearchParams({ page: page + 1 })
        }

    };

    useEffect(() => {
        // Making sure user starts at page 0 if he used a link to this page
        setSearchParams({ page: 0 }, { replace: true })

        axios.get('/categories', { params: { enabled: true } })
            .then(result => {
                setCategories(result.data?.categories)
            })
            .catch(error => {
                console.error(error)
            })
    }, [])

    useEffect(() => {
        if (page > 2 || page === 2 && !isEmployer) {
            setSearchParams({ page: 0 }, { replace: true })
        }
    }, [page, activeButton])

    return (
        <div className='LoginPage'>
            <div className='LoginWrapper'>
                <div className='RightSide'>
                    <div style={{}}>
                        {page === 0 ? (
                            <>
                                <div style={{ marginTop: '2%', display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', height: '85svh' }}>

                                    <h3 id='CreateAccountText'>Create Account</h3>
                                    {/* <h5 style={{ textAlign: 'left', padding: 0, margin: 0, marginTop: 10, marginBottom: 30 }}>Choose between Full-time or Internship</h5> */}
                                    <div className='SignUpButtons'>
                                        <div
                                            className={`big-button ${isEmployer ? 'active' : ''}`}
                                            onClick={() => updateFormField('role')({ target: { value: 'employer' } })}
                                        >
                                            <h3>Employer</h3>
                                            <h5>Looking for top talent? Join Foras and connect with skilled freelancers today.</h5>
                                        </div>
                                        <div
                                            className={`big-button ${!isEmployer ? 'active' : ''}`}
                                            onClick={() => updateFormField('role')({ target: { value: 'employee' } })}
                                        >
                                            <h3>Students - Graduates</h3>
                                            <h5>Ready to find your next gig? Sign up on Foras and start your journey.</h5>
                                        </div>
                                    </div>
                                    <button style={{ cursor: 'pointer' }} id='handleNextButton' className='next-button' onClick={handleNext}>Next</button>
                                    {page === 0 && (
                                        <div className='LoginCreateAccount'>
                                            <Link style={{ fontSize: 12, color: '#34AED4', fontWeight: 600 }} to='/Login'>Have An Account?</Link>
                                        </div>
                                    )}
                                </div>
                            </>
                        ) : (<>
                            {page === 1 ? (
                                <form onSubmit={handleNext} id='form1' className='SignUpForm'>
                                    <h3>{isEmployer ? "Company " : ""}Name</h3>
                                    <input required type="text" id="fName" name="fName" placeholder='First Name' value={formState.firstName || ''} onChange={updateFormField('firstName')} /><br />
                                    {!isEmployer && (
                                        <>
                                            <input required type="text" id="lName" name="lName" placeholder='Last Name' value={formState.lastName || ''} onChange={updateFormField('lastName')} /><br />
                                            <h3>Date of Birth</h3>
                                            <input required type="date" id="dob" name="dob" placeholder="Enter Date" value={formState.dob || ''} onChange={updateFormField('dob')} /><br />
                                        </>
                                    )}

                                    <h3>Choose a username</h3>
                                    <input required type="text" id="username" name="username" placeholder='Username' value={formState.username || ''} onChange={updateFormField('username')} /><br />
                                    <h3>{isEmployer ? "Business " : ""}Email</h3>
                                    <input required type="email" id="email" name="email" placeholder={isEmployer ? "info@yourcompany.com" : "Email"} value={formState.email || ''} onChange={updateFormField('email')} /><br />
                                    {isEmployer && <>
                                        <h3>Company Website</h3>
                                        <input required type="text" id="website" name="website" placeholder='https://www.yourcompany.com' value={formState.website || ''} onChange={updateFormField('website')} /><br />
                                    </>}
                                    <h3>Password</h3>
                                    <input required type="password" id="pass" name="pass" autoComplete='new-password' placeholder='Password' value={formState.password || ''} onChange={(e) => { checkPassStrength(e, setStrength); updateFormField('password')(e) }} />
                                    <h3>Re-Enter Password</h3>
                                    <input required type="password" id="pass2" name="pass2" autoComplete='new-password' placeholder='Re-Enter Password' value={formState.password2 || ''} onChange={updateFormField('password2')} /><br />

                                    {loading ? <div style={{ height: '30px' }}><Loading size={20} /></div> : <input required type="submit" value={shouldSignUp ? 'Submit' : "Next"} />}
                                </form>
                            ) : (isEmployer && (
                                <form onSubmit={handleNext} autoComplete='off' id='form2' className='SignUpForm'>
                                    <input autoComplete='false' style={{ display: 'none' }} />

                                    <h3>Company Industry</h3>
                                    <div id='ChooseIndustryDropDown'>
                                        <select required name="industry" id="industry" value={formState.industry || ''} onChange={updateFormField('industry')}>
                                            <option value="" disabled>
                                                Choose Industry
                                            </option>
                                            {categories.map(({ _id, name }) => (
                                                <option key={_id} value={_id}>
                                                    {name}
                                                </option>
                                            ))}
                                        </select>
                                    </div>

                                    <h3>Company Location</h3>
                                    <input required type="text" id="location" name="location" placeholder='Location' value={formState.location || ''} onChange={updateFormField('location')} /><br />

                                    <h3>Primary Contact Name</h3>
                                    <input required type="text" id="contactName" name="contactName" placeholder='Primary Contact Name' value={formState.contactName || ''} onChange={updateFormField('contactName')} /><br />

                                    <h3>Primary Contact Job Title</h3>
                                    <input required type="text" id="contactTitle" name="contactTitle" placeholder='Primary Contact Job Title' value={formState.contactTitle || ''} onChange={updateFormField('contactTitle')} /><br />

                                    <h3>Primary Contact Email</h3>
                                    <input required type="email" id="contactEmail" name="contactEmail" placeholder='Primary Contact Email' value={formState.contactEmail || ''} onChange={updateFormField('contactEmail')} /><br />

                                    <h3>Primary Contact Phone Number</h3>
                                    <input required type="text" id="contactPhone" name="contactPhone" placeholder='Primary Contact Phone Number' value={formState.contactPhone || ''} onChange={updateFormField('contactPhone')} /><br />

                                    {loading ? <div style={{ height: '30px' }}><Loading size={20} /></div> : <input required type="submit" value={shouldSignUp ? 'Submit' : "Next"} />}
                                </form>
                            ))}
                            {/* {error && <div style={{ color: 'red', }}>{error}</div>} */}
                            {errors.length > 0 && (
                                <AlertModal
                                    show={true}
                                    text="Please correct the following errors:"
                                    alertText={errors[0]}
                                    widthController={50}
                                    buttonsVisible={true}
                                    smallalert = {true}
                                    b1={{ text: "Close", onClick: () => setErrors([]) }}
                                />
                            )}

                        </>)}
                    </div>
                    {page > 0 && (
                        <>
                            <h3 style={{ fontSize: 12, textAlign: 'center' }}>By creating this account, you agree to our <a target="_thapa" href='TermsCondition'>Terms and Conditions</a></h3>
                        </>
                    )}
                </div>
                <ImageBatch />
            </div>
        </div>
    );
}

