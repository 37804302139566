import React from 'react';
import "./stylesheets/Status.css";

export default function Status({getStatus, isUpdated}) {
    return (
        <div id='CircleStatusWrapper'>

            <div id='CircleStatusArea'>
                <div id='CircleStatusAndText'>
                    <div style={{display:'flex', alignItems:'center'}}>
                    <div id='CircleStatus' style={{backgroundColor: isUpdated? 'black': 'white'}}></div>
                    <div id='Line'></div>
                    </div>
                    <div className='StatusText'>Application Submitted</div>
                </div>
            </div>

            <div id='CircleStatusArea'>
                <div id='CircleStatusAndText'>
                    <div style={{display:'flex', alignItems:'center'}}>
                    <div id='CircleStatus' style={{backgroundColor:'white'}}></div>
                    <div id='Line'></div>
                    </div>
                    <div className='StatusText' style={{marginLeft:-10}}>In Review</div>
                </div>
            </div>

            <div id='CircleStatusArea'>
                <div id='CircleStatusAndText'>
                    <div style={{display:'flex', alignItems:'center'}}>
                    <div id='CircleStatus' style={{backgroundColor:'white'}}></div>
                    <div style={{width:50, backgroundColor:'transparent'}} id='Line'></div>
                    </div>
                    <div className='StatusText' style={{marginLeft:-5}}>Result</div>
                </div>
            </div>

        </div>
    )
}
