export function formatText(text) {
    const split = text.split('*')
    // Number of elements should be odd since even number of asterisks means odd number of segments e.g. "1*2*3"
    if (split.length % 2 === 0) {
        return text
    }

    const bold = []
    for (let i = 0; i < split.length; i++) {
        if (i % 2 === 0) {
            bold.push(split[i])
        } else {
            bold.push(<span style={{ fontWeight: 'bold' }}>{split[i]}</span>)
        }
    }
    return bold
}

/** 
 * Format the given text by safely replacing all `\n` characters with <br/> tags.
 * @param {string} text Text to format
 * @param {number} [lineLimit=0] Limits the number of lines parsed. Defaults to `0`, which means no limit.
 */
export function formatNewLine(text, lineLimit=0){
    const split = text.split('\n')

    const result = []
    for(const line of split){
        result.push(line)
        result.push(<br/>)

        lineLimit -= 1
        if(lineLimit === 0) 
            break;
    }

    // Remove trailing <br/>
    result.pop()

    return result;
}