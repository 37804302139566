import axios from "axios";
import { useCallback, useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { BACKEND } from "../config";
import { deleteToken } from "../controllers/AuthController";

export default function useAxiosDefaults() {
    const [navToLogin, setNavToLogin] = useState(false)
    const [alertText, setAlertText] = useState()
    const navigate = useNavigate()
    const location = useLocation()
    axios.defaults.baseURL = BACKEND
    axios.defaults.withCredentials = true

    const clearAlert = useCallback(() => setAlertText(null), [setAlertText])

    // TODO: Maybe centralize error handling using this interceptor?
    // Automatically navigating user to login page if unauthorized code received
    useEffect(() => {
        axios.interceptors.response.use(
            function (response) {
                return response;
            },
            function (error) {
                if (error.response?.status === 401) {
                    deleteToken()
                    setNavToLogin(true)
                } else if (error.response?.status === 404 && error.response?.data instanceof Blob) { // Ignoring 404 resposes with file or image data
                    throw error
                } else {
                    console.error(error)

                    // Skip NOT_ENOUGH_CREDITS error as it is handled in its own page
                    if (error.response?.data?.name === 'NOT_ENOUGH_CREDITS') {
                        throw error;
                    }
                    if(error.response?.data){
                        setAlertText(`${error.response.statusText}: ${error.response.data?.toString()}`)
                    } else {
                        setAlertText(error.message)
                    }
                }
                throw error
            })
    }, [])

    useEffect(() => {
        if (!navToLogin) {
            return;
        }
        navigate('/Login', { state: { from: location.pathname + location.search } })
        setNavToLogin(false)
    }, [navToLogin])

    return {
        alertText, clearAlert
    }
}