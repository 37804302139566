import React, { useCallback, useEffect, useMemo, useState } from 'react'
import "./stylesheets/Notifications.css"
import JohnCena from './pages/stylesheets/imgs/jocena.jpg'
import Loading from './pages/components/Loading';
import axios from 'axios';
import { useLocation, useNavigate } from 'react-router';
import { useUser } from './hooks/useUser';
import { NOTIFS_PER_PAGE } from './config';
import { formatText } from './utils/TextUtils.js';

const NotificationTab = ({ notif }) => {

    const navigate = useNavigate()
    const navToNotif = useCallback(() => {
        navigate(`/ApplyForas/${notif.doc.split('#')[0]}`)
    }, [notif])
    const formattedDate = useMemo(() => new Date(notif.updatedAt).toLocaleString('en-eg',
        {
            month: 'long',
            day: 'numeric',
            year: 'numeric',
            hour: 'numeric',
            minute: '2-digit'
        }), [notif])
    return (
        <div onClick={navToNotif} className='NotifyTab' style={{ userSelect: 'none' }}>
            <h4 style={{ margin: 0, fontSize: '1.5svh', color: 'black' }}>{notif.title}</h4>
            <h5 style={{ margin: 0, fontSize: '1.5svh', color: '#3e3e3e', width: '67%', textAlign: 'center' }}>{formatText(notif.body)}</h5>
            <h5 style={{ margin: 0, textAlign: 'center', fontSize: '1.25svh', color: '#34AED4' }}>{formattedDate}</h5>
        </div>
    )
};

const UpcomingDates = ({ Image, Title, Description, Date, onClick }) => {
    return (
        <>
            <div onClick={onClick} className='UpcomingDates'>
                {/* <img src={Image} style={{ width: 60, height: 60, borderRadius: 30, marginTop: 10, marginLeft: 10 }} /> */}
                {/* <h5 style={{ margin: 0, padding: 0, textAlign: 'center', fontSize: 10, paddingBottom: 5 }}>{Title}</h5> */}

                <div style={{}}>
                    <h4 style={{ color: 'black', fontWeight: 600 }}>{Title}</h4>
                    <h5 style={{ textWrap: 'wrap', fontSize: '1.5svh', lineHeight: '2svh' }}>{Description.length > 150 ? Description.substring(0, 150) + '...' : Description}</h5>
                    <div style={{}}>
                        <h5 className='datedesign' style={{ color: 'black' }}>Last Updated:</h5>
                        <h5 style={{ textAlign: 'center', fontSize: 10 }} className='datedesign'>{Date}</h5>
                    </div>
                </div>
            </div>
        </>
    )
};

const availableAppStatuses = {
    invitation: {
        name: 'Invitations',
        url: '/applications/forUser',
        frontendUrl: '/ApplyForas',
        params: { accepted: false, sort: 'updatedAt', }
    },
    accepted: {
        name: "Accepted",
        url: '/applications/forUser',
        frontendUrl: '/ApplyForas',
        params: { accepted: true, sort: 'updatedAt', }
    },
    progress: {
        name: 'Active',
        frontendUrl: '/ActiveForsa',
        url: `/jobs/listByStatus`,
        params: { status: 'progress' }
    },
    review: {
        name: 'In Review',
        frontendUrl: '/ActiveForsa',
        url: '/jobs/listByStatus',
        params: { status: 'review' }
    },
    complete: {
        name: "Complete",
        frontendUrl: '/ActiveForsa',
        url: '/jobs/listByStatus',
        params: { status: 'complete' }
    },
}

export default function Notifications() {
    // Current user data
    const { id: curUserId, isRole } = useUser()

    // Notification states
    const [notifs, setNotifs] = useState(undefined)
    const [page, setPage] = useState(0)

    // Right Panel states
    const [selectedAppStatus, setSelectedAppStatus] = useState('invitation')
    const [showNotifications, setShowNotifications] = useState(false)
    const [dataList, setDataList] = useState()

    // Navigation hooks
    const location = useLocation()
    const navigate = useNavigate()

    function genNotifications() {
        if (!notifs) {
            return <Loading />
        }

        if (notifs.length === 0) {
            return <div><h3 style={{ textAlign: 'center' }}>
                You have no notifications
            </h3>
            </div>
        }

        const currentNotifs = notifs.slice(page * NOTIFS_PER_PAGE, (page + 1) * NOTIFS_PER_PAGE)
        return currentNotifs.map(notif => <NotificationTab key={notif._id} notif={notif} />)
    }

    function handleSelectedAppStatusChange(key) {
        setShowNotifications(false);  // Ensure notifications are hidden
        setSelectedAppStatus(key);

        const appStatusObj = availableAppStatuses[key];
        axios.get(appStatusObj.url + `/${curUserId}`, { params: { ...appStatusObj.params, employer: isRole('employer') } })
            .then(result => {
                // Workaround to keep schema similar for all keys
                if (['progress', 'review', 'complete'].includes(key)) {
                    result.data.map(e => e.job = { ...e });
                }
                setDataList(result.data);
            })
    }




    useEffect(() => {
        var lastDate;

        axios.get(`/notifications/${curUserId}`, { params: { lastDate, limit: NOTIFS_PER_PAGE + 1 } })
            .then(result => {
                setNotifs(result.data)
            })
            .catch(error => {
                console.error(error)
                setNotifs([])
            })
    }, [page])

    useEffect(() => {
        handleSelectedAppStatusChange(selectedAppStatus)
    }, [])

    const [windowWidth, setWindowWidth] = useState(window.innerWidth);
    return (
        <>
            <div className='NotificationsPage'>
                {
                    windowWidth > 1024 ?
                        ''
                        :
                        <h3 style={{textAlign:'center', padding:'2.5%', fontSize:'1.5svh'}}>Control Center</h3>
                }
                <div id='leftNotifications' style={{ display: 'flex', flexDirection: 'column' }}>
                    {
                        windowWidth > 1024 ?
                            <h3>Control Center</h3>
                            :
                            ''
                    }
                    <button
                        onClick={() => {
                            setSelectedAppStatus(null);  // Deactivate other buttons
                            setDataList([])
                            setShowNotifications(true);  // Ensure notifications are shown
                        }}
                        id={showNotifications ? "hoverOn" : "hoverOff"}
                        style={{ backgroundColor: showNotifications ? '#34AED4' : 'white', color: showNotifications ? 'white' : '#34AED4' }}>
                        Notifications
                    </button>

                    {Object.entries(availableAppStatuses).map(([key, value]) => {
                        return (
                            <button
                                key={key}
                                onClick={() => handleSelectedAppStatusChange(key)}
                                id={key === selectedAppStatus ? "hoverOn" : "hoverOff"}
                                style={{ backgroundColor: key === selectedAppStatus ? '#34AED4' : 'white', color: key === selectedAppStatus ? 'white' : '#34AED4' }}>
                                {value.name}
                            </button>
                        )
                    })}
                </div>

                <div id='UpcomingNotifications' style={{ padding: '2.5%' }}>
                    {
                        dataList?.map((e, i) => {
                            return (
                                <UpcomingDates
                                    key={i}
                                    Title={e.job.title}
                                    Description={e.job.description}
                                    Date={new Date(e.updatedAt).toLocaleString('en-eg',
                                        {
                                            month: 'long',
                                            day: 'numeric',
                                            year: 'numeric',
                                            hour: 'numeric',
                                            minute: '2-digit'
                                        })}
                                    onClick={() => navigate(`${availableAppStatuses[selectedAppStatus].frontendUrl}/${e.job._id}`)}
                                />
                            )
                        })
                        || <Loading />
                    }
                    {showNotifications && (
                        <div className='NotificationPanel'>
                            <h3 style={{ textAlign: 'center', marginTop: 25, marginBottom: 25 }}>Notifications</h3>
                            {genNotifications()}
                        </div>
                    )}

                </div>

            </div>
        </>
    )
}
